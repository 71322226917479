import { Injectable } from '@angular/core';
import { Router, CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {  LoginService } from '../lib/service/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieManagerService } from '../lib/service/cookie.service';
import { FunctionService } from "../lib/service/function.service";

@Injectable()
export class AuthGuard implements CanActivate , CanActivateChild{

    constructor(private router: Router,
                private loginService: LoginService,
                private cookie:CookieManagerService,
                private functionService:FunctionService
                ) {

    }
      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       this.functionService.setSide(true);
        if(this.cookie['api_token'] != undefined && this.cookie['api_token'] != ""){
           console.log("Con Token");
          return true;
        }else{
            console.log("Sin Token");
          this.router.navigate(['/login']);
          return false;
        }
        return true;

    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
