import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationExtras } from "@angular/router";
import { FunctionService } from "../lib/service/function.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router,
  			private functionService:FunctionService) {
  	//this.functionService.setSide(false);
  }

  ngOnInit() {

  }
  gotoLogin(){
  	this.router.navigate(['/consult']);
  }

}
