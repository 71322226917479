import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationExtras } from "@angular/router";
import { FpFotoPredioService  } from '../lib/service/fpfotopredio.service';
import { FpPrediosService  } from '../lib/service/fppredios.service';
import {ErrorDialogComponent} from '../lib/component/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../lib/component/confirmation-dialog/confirmation-dialog.component';
import { CookieManagerService } from "../lib/service/cookie.service";
import {DomSanitizer} from '@angular/platform-browser';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-registrar_datos',
  templateUrl: './registrar_datos.component.html',
  styleUrls: ['./registrar_datos.component.scss']
})
export class RegistrarDatosComponent implements OnInit {
  
  public predio={
    "id":null,
    "predio_id":null,
    "direccion_completa":"",
    "referencia":"",
    "apellidos_nombres":"",
    "suministro":"",
    "observacion":"",
    "numero_manzana":null,
    "usuaio_foto_id":null
  };

  public imagenes = [];

  public motiviNotificacion:any=[];
  public sideFlag;
  public topPosition;
  public position;
  public second;
  public selectedOption = {
    'descripcion':"",
    'id':undefined
  };
  public dni;
  public showDni;
  public imagen;
  public imgagen_64;
  public urlBase;
  public fechaSiguienteVisita;
  public txtFechaSiguienteVisita;

  public motiviNotificacionPrimeraVisita:any=[];

  constructor(private router: Router,
            private fpFotoPredioService:FpFotoPredioService,
            private fpPrediosService:FpPrediosService,
            private route: ActivatedRoute,
            public dialog: MatDialog,
            private cookie:CookieManagerService,
            private sanitizer:DomSanitizer) {
      this.showDni = true;
      this.imagen = "./assets/images/image_icon.png";
      this.imgagen_64 = "";
      this.urlBase = environment.serverURLImg;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {  
        this.getPredio(params['id']);
    });
  }

  onSelected(event){
    this.selectedOption = event;
    console.log(event);
    console.log(this.selectedOption);
  }

  onFileChange(event){
    this.imgagen_64 = "";
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var ruta =  window.URL.createObjectURL(event.target.files[0]) ;
      this.imagen   = this.sanitizer.bypassSecurityTrustUrl(ruta);

      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imgagen_64 = "data:"+file.type+";base64,"+reader.result.split(',')[1] ;

        if(this.imgagen_64 != '' && this.imgagen_64 != null && this.imgagen_64 != undefined){
            var predio_id = 9999999999;
            if(this.predio.predio_id != null){
              predio_id = this.predio.predio_id;
            }
            var dataImg = {
              'manzana' : this.predio.numero_manzana,
              'predio' : predio_id,
              'img':this.imgagen_64
            }       

            this.fpFotoPredioService.registrarFoto(dataImg).subscribe((data) => {
                var image = {
                  "url":data.data,
                  "id_predio":this.predio.id,
                  "estado":1,
                  "usuario_id": this.cookie['id_user'],
                  "img": this.urlBase + data.data
                }

                this.imagenes.push(image);

              },
              (err) => {  
                let er = JSON.parse(err._body);
                console.log(er.message );
                this.mensajeError(er.message);
              });
        }else{
          this.mensajeError("Por favor, seleccione foto");  
        }

      };
    }
  } 

  changeEstado(img){
    for (var i = 0; i < this.imagenes.length; i++) {
      if(this.imagenes[i].url == img.url){
        this.imagenes[i].estado = 2;
      }
    }
  }

  guardar(){
    if(this.imagenes == null ){
        this.mensajeError("Registra almenos una foto.");
    }else{
      var cantidad =0;
      for(var i=0; i<this.imagenes.length; i++){
        if(this.imagenes[i].estado == 1){
          cantidad++;
        }
      }
      if(cantidad == 0){
        this.mensajeError("Registra almenos una foto.");
      }

      var dataEnvio = {
        "imagenes":this.imagenes,
        "id":this.predio.id,
        "suministro":this.predio.suministro,
        "observacion":this.predio.observacion,
        "usuaio_foto_id":this.cookie['id_user']
      }

      console.log(dataEnvio)

        this.fpPrediosService.registraDatos(dataEnvio).subscribe((data) => {
          console.log("Se registró correctamente.");
          this.router.navigate(['/predios/'+this.predio.numero_manzana]);
        },
        (err) => {  
          let er = JSON.parse(err._body);
          console.log(er.message );
          this.mensajeError(er.message);
        });

    }

  }
 
  changeScreen(){

  }
  autocomplete(event){

  }

  limpiar(){
    this.selectedOption = undefined;
    this.fechaSiguienteVisita = undefined;
    this.dni = undefined;
    this.imagen = "./assets/images/image_icon.png";
    this.imgagen_64 =undefined;
  }

  getPredio(id){
    this.fpPrediosService.getPredio(id).subscribe((data) => {
      this.predio = data.data;
    },
    (err) => {  
      let er = JSON.parse(err._body);
      this.mensajeError(er.message);
    });
  }

  mensajeError(mensaje){
    let dialogRef = this.dialog.open(ErrorDialogComponent,
        {
            width:'250px',
            data:{
              flag:true,
              title:'Error',
              message:'Aceptar',
              code:mensaje
            }
        }
      );
  }

  

}
