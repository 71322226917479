import { Injectable } from '@angular/core';
import { Http, Response, Headers,RequestOptions} from '@angular/http';
import {map} from 'rxjs/operators';
//server side rendering and service worker modules
import { PLATFORM_ID, APP_ID, Inject,Optional } from '@angular/core';
import { isPlatformBrowser,APP_BASE_HREF } from '@angular/common';
//Service that controls any function or config needed for server rendering
@Injectable()
export class ServerService {
    private base: string = '/assets/json/';
    private headers = new Headers();
    private origin:string;


    constructor(private http: Http,@Inject(PLATFORM_ID) private platformId: Object,@Inject(APP_ID) private appId: string,
      @Optional() @Inject(APP_BASE_HREF) private serverURL: string){

        this.origin="";
        // console.log(this.serverURL);
    }

    //1:browser, 0:server;
    getPlatform():number{
      const platform = isPlatformBrowser(this.platformId) ?
      1:0;
      return platform;
    }

    getAbsoluteURL(url){
      //this.origin = __dirname +"/";
      return `${this.origin}${url}`;
    }

    getLocation(platformLocation: any){
      this.origin = platformLocation.location;
    }
}
