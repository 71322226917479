import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationExtras } from "@angular/router";
import { LoginService } from "../lib/service/login.service";
import { CookieManagerService } from "../lib/service/cookie.service";
import { FunctionService } from "../lib/service/function.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public username;
  public password;
  public inputType: string;

  public errorLogin:boolean;
  public menssageErrorLogin:string;
  constructor(private router: Router,
              private loginService:LoginService,
              private cookie:CookieManagerService,
              private functionService:FunctionService) { }

  ngOnInit() {
    //this.functionService.setSide(false);
    this.inputType = "password";
    this.errorLogin = false;
    this.menssageErrorLogin = ""; 
  }

  gotoConsult(){ 
    let user= {
      "email":this.username,
      "password":this.password,
      "remember_me":true
    }

  this.loginService.getToken(user).subscribe((data) => {
      console.log(data);
      this.cookie.addCookie('api_token',JSON.stringify("Bearer " + data.access_token) );
      if (this.cookie['api_token'] != undefined && this.cookie['api_token'] != "") {
        this.errorLogin = false;
        this.menssageErrorLogin = "";

        this.loginService.infoUser().subscribe((data) => {
          this.cookie.addCookie('id_user',data.id );

          localStorage.setItem('currentUser', data.name);
          this.functionService.setUserName(data.name);
          localStorage.setItem('email_user', data.email);
          this.functionService.setEmailUser(data.email);
          
          console.log(data);
          this.router.navigate(['/manzanas']);       
        
        },(err) => {
          let er = JSON.parse(err._body);
          this.errorLogin = true;
          this.menssageErrorLogin = er.message
          console.log(this.menssageErrorLogin );
        });
        

      }else{
        this.errorLogin = true;
        this.menssageErrorLogin = "Error al iniciar sección, inténtelo nuevamente.";
      }
      
    },
    (err) => {
      let er = JSON.parse(err._body);
      this.errorLogin = true;
      this.menssageErrorLogin = er.message
      console.log(this.menssageErrorLogin );
    });
  }

  onChange() {
    if (this.inputType == "password") {
      this.inputType = "text";
    } else {
      this.inputType = "password";
    }
  }

}
