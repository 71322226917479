import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationExtras } from "@angular/router";
import {ErrorDialogComponent} from '../lib/component/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../lib/component/confirmation-dialog/confirmation-dialog.component';
import { CookieManagerService } from "../lib/service/cookie.service";
import {DomSanitizer} from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { FpManzanasService  } from '../lib/service/fpmanzanas.service';
import { FpTipoViasService  } from '../lib/service/fptipovias.service';
import { FpPrediosService  } from '../lib/service/fppredios.service';

@Component({
  selector: 'app-registrar_predios',
  templateUrl: './registrar_predios.component.html',
  styleUrls: ['./registrar_predios.component.scss']
})
export class RegistrarPrediosComponent implements OnInit {
	
  /*public predio={
    "tipo_via_id":null,
    "nombre_via":"",
    "numero":"", 
    "manzana":"", 
    "lote":"",
    "referencia":""
  };*/
  public tipoVias = [];
  public predio={
    'dj_id':null ,
    'predio_id':null,
    'codigo_predio': '',
    'persona_id':null,
    'apellidos_nombres': '',
    'sector_id':null,
    'descripcion_sector': '',
    'lugar_id':null,
    'descripcion_lugar': '',
    'via_id':null,
    'descripcion_via': '',
    'tipo_via_id':null,
    'descripcion_tipo_via': '',
    'numero_manzana':null,
    'lado_cuadra': '',
    'numero_cuadra': '',
    'direccion_completa': '',
    'numero': '',
    'numero2': '',
    'letra': '',
    'letra2': '',
    'manzana': '',
    'lote': '',
    'referencia': '',
    'estado':1,
    'flag_validado':0,
    'suministro':'',
    'observacion':'',
    'glosa':'',
    'latitud':'',
    'longitud':'',
    'usuario_registra_id':null
  }

  selectedOption = "";
  sideFlag;
  second;
  topPosition;

  constructor(private router: Router,
            private fpTipoViasService:FpTipoViasService,
            private route: ActivatedRoute,
            public dialog: MatDialog,
            private cookie:CookieManagerService,
            private fpManzanasService:FpManzanasService,
            private fpPrediosService:FpPrediosService,
            private sanitizer:DomSanitizer) {

    this.fpTipoViasService.getTipoVias().subscribe((data) => {
        this.tipoVias = data.data;
      },
      (err) => {
        console.log(err);  
      });  

    var numero_manzana = localStorage.getItem('numero_manzana');

    if(numero_manzana === null || numero_manzana ==='' ){
      this.router.navigate(['/manzanas']);     
    }else{
      this.fpManzanasService.getManzana(numero_manzana).subscribe((data) => {
        this.predio.numero_manzana = data.data.numero_manzana;
        this.predio.sector_id = data.data.sector_id;
        this.predio.descripcion_sector = data.data.descripcion_sector;
        this.predio.lugar_id = data.data.lugar_id;
        this.predio.descripcion_lugar = data.data.descripcion_lugar;
      },
      (err) => {
        this.router.navigate(['/manzanas']);   
      });  
    }
  }
  changeScreen(){

  }
  autocomplete(event){

  }
  ngOnInit() {
   
  }

  guardar(){
    this.predio.usuario_registra_id =  this.cookie['id_user'];

    //Avenida San Martín de porres - - /
    //  Pasaje Los manantiales 173 - - /  
    //  Pasaje Los manantiales 173 - - /  

    /*this.predio.direccion_completa = this.predio.descripcion_tipo_via + ' ' +this.predio.descripcion_via +
            +this.predio.numero+' '+' '+this.predio.letra + ' - ' +this.predio.numero2+ ' ' + this.predio.letra2 
            ' ' + this.predio.manzana +' ' + this.predio.lote + '/'+this.predio.referencia;
    console.log(this.predio.direccion_completa);*/

    this.fpPrediosService.savePredio(this.predio).subscribe((data) => {
      //this.tipoVias = data.data;
      this.router.navigate(['/predios/'+this.predio.numero_manzana]);
    },
    (err) => {
      console.log(err);  
    }); 
    //this.router.navigate(['/predios/1']); 
  }

  onSelected(event){
    this.predio.tipo_via_id = event.tipo_via_id;
    this.predio.descripcion_tipo_via = event.descripcion;
  }
  

  

}
