import { Component, ChangeDetectorRef, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, HostListener, IterableDiffers } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { Router, NavigationEnd, ActivationEnd, ChildActivationEnd, ResolveEnd } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { startWith ,  map } from 'rxjs/operators';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatSelect, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


//Services
import { CookieManagerService } from '../lib/service/cookie.service';
import { LoginService } from '../lib/service/login.service';
import { SideNavService } from '../lib/service/sidenav.service';

declare var $:any;

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss'],
  providers: [CookieManagerService]
})
export class SideComponent implements OnInit {
  isSearching: boolean;

  public actualLabel;
  public valueSearch: string;
  public isCategory: boolean;
  public secondLevel: boolean;

  public params:any;

  sideTop;
  activeSidenav = false;
  addPredios = false;

  @Input() sideFlag;
  @Input() positionType
  @Output() autoComplete: EventEmitter<any> = new EventEmitter();

  @Input() topPosition:any;
  @Input() swipe;
  @Output() searchFlag: EventEmitter<any> = new EventEmitter();
  @ViewChild('snav') sidenav: MatSidenav;
  @ViewChild('snavContainer') sidenav_container: MatSidenavContent;
  @ViewChild("sideOptions") sideOptons: MatSelect;
  @ViewChild('navmenu') nav: ElementRef; 


  constructor(
    public router: Router,
    private cookie: CookieManagerService,
    private myElement: ElementRef,
    private loginService: LoginService,
    public dialog: MatDialog,
    private _location: Location,
    private _iterableDiffers: IterableDiffers,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private sideNavService:SideNavService

  ) {
    this.sideTop = '0px';
  }

  ngOnInit() {
    this.isSearching = false;
    this.setLabel();
  } 

  setLabel() {
    console.log("setLabel"+ this.router.url.indexOf("registrar_predios"));
    this.secondLevel = false;
    this.addPredios = false;
    
     

    if (this.router.url.indexOf("registrar_datos") != -1) {
      this.actualLabel = "CAPTURA DE FOTOS"
      this.isCategory = true;
      this.secondLevel = true;
      this.addPredios = false; 
    }

    if (this.router.url.indexOf("predios") != -1) {
      this.actualLabel = "LISTA DE PREDIOS"
      this.isCategory = true;
      this.secondLevel = true;
      this.addPredios = true;
    }

    if (this.router.url.indexOf("registrar_predios") != -1) {
      this.actualLabel = "REGISTRAR PREDIO"
      this.isCategory = true;
      this.secondLevel = true;
      this.addPredios = false;
    }


  }

  toggle() {
    this.activeSidenav = !this.activeSidenav;
    this.sideNavService.setToggle(this.activeSidenav);
  }

  goTo(opt) {
    if(opt === 2){
     this.loginService.logout().subscribe((data) => {
       
    },
    (err) => {
    });
    this.cookie.removeCookie('api_token');
    this.router.navigate(['/login']);
    }
  }

  toogleOptions() {
    this.sideOptons.toggle();
  }

  reverse() {
    console.log(this.router.url);

    if (this.router.url.indexOf("predios") != -1) {
      this.router.navigate(['/manzanas']);    
    }else{
      this._location.back();  
    }
    
  }

  close(reason: string) {
    this.sidenav.close();
  }

  agregarPredio(){
    this.router.navigate(['/registrar_predios']);
  }
  

}
