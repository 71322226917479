import { Injectable } from '@angular/core';

@Injectable()
export class TabService {
    public flagLogin: number;
    public flagProfile: number;
    public flagAddress:number;

    constructor(){
      this.flagLogin = 0;
      this.flagAddress=0;
      this.flagProfile=0;
    }

    setRegister(){
      this.flagLogin = 1;
    }

    setLogin(){
      this.flagLogin = 0;
    }

    setAddressIndex(value){
      this.flagAddress=value;
    }

    resetAddressIndex(){
      this.flagAddress=0;
    }

    getAdressIndex(){
      return this.flagAddress;
    }


    setProfileIndex(value){
      this.flagProfile=value;
    }

    resetProfileIndex(){
      this.flagProfile=0;
    }

    getProfileIndex(){
      return this.flagProfile;
    }

}
