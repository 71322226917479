
import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Inicio Componentes */
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ManzanasComponent } from './manzanas/manzanas.component';
import { PrediosComponent  } from './predios/predios.component';
import { RegistrarDatosComponent  } from './registrar_datos/registrar_datos.component';
import { RegistrarPrediosComponent  } from './registrar_predios/registrar_predios.component';
/* Fin Componentes */
import { AuthGuard } from './_guards/auth.guard';
const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
      path: 'home', component: HomeComponent,
    },
    {
      path: 'login', component: LoginComponent,
    },
    {
      path: 'manzanas', canActivate: [AuthGuard] , component: ManzanasComponent, 
    },
    {
      path: 'predios/:id', canActivate: [AuthGuard] , component: PrediosComponent, 
    },
    {
      path: 'registrar_datos/:id', canActivate: [AuthGuard] , component: RegistrarDatosComponent, 
    },
    {
      path: 'registrar_predios', canActivate: [AuthGuard] , component: RegistrarPrediosComponent, 
    },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
