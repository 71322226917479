import { Injectable } from '@angular/core';
import {finalize, map} from 'rxjs/operators';
import { Observable,Subject} from 'rxjs/Rx';

import { DOCUMENT } from '@angular/common';

import { ServerService } from './server.service';
//Service that get all the images that are shown in modals
@Injectable()
export class ConfigService {
  public configReady: boolean;

  public configLoading$: Observable<any>;
  private configLoaded = new Subject<any>();

  public updateConfig$: Observable<any>;
  private configSubject = new Subject<any>();

  public updateSlides$: Observable<any>;
  private configSlides = new Subject<any>();
  private currentPlatform: any;

  constructor(private serverService: ServerService){
    this.configReady=false;
    this.configLoading$ = this.configLoaded.asObservable();
    this.updateConfig$ = this.configSubject.asObservable();
    this.updateSlides$ = this.configSlides.asObservable();
    this.currentPlatform = this.serverService.getPlatform();
  }

  public loadConfig(status){
    this.configReady= status;
    this.configLoaded.next(status);
  }

  public getStatus(){
    return this.configReady;
  }

  setGeneralConfig(flag) {
    this.configSubject.next(flag);
  }

  setProjectConfig(data) {
    if (!this.currentPlatform) return;
    // console.log(data);
    for (var i = 0; i < data.length; i++) {
      if (data[i].name == "index") {
        document.querySelector("#title").textContent = data[i].data.title;
        //$("#title").text(data[i].data.title);
        // console.log(data[i]);
        document.querySelector("#icon").setAttribute('href', data[i].data.icon);
        // console.log(data[i].data.img_loader);
        // document.querySelector("img#img_loader").setAttribute('src', data[i].data.img_loader);

        // $("#icon").attr('href',data[i].data.icon);
        // $("#img_loader").attr('src',data[i].data.img_loader);

        // $("#icon").attr('href',data[i].data.icon);
        if (data[i].data.manifest.status) {
          // console.log(data[i].data.manifest);
          // $("#manifest").attr('href',data[i].data.manifest.link);
          document.querySelector("#manifest").setAttribute('href', data[i].data.manifest.link);

        }
        if (data[i].data.chat_social.status) {
          (function() {
            var options = {
              facebook: data[i].data.chat_social.facebook_id, // Facebook page ID
              whatsapp: data[i].data.chat_social.whatsapp_number, // WhatsApp number
              company_logo_url: data[i].data.chat_social.company_logo_url, // URL of company logo (png, jpg, gif)
              greeting_message: data[i].data.chat_social.greeting_message, // Text of greeting message
              //call_to_action: "¿En qué podemos ayudarte?", // Call to action
              button_color: data[i].data.chat_social.button_color, // Color of button
              position: data[i].data.chat_social.position, // Position may be 'right' or 'left'
              order: data[i].data.chat_social.order // Order of buttons
            };
            /*fashion....*/
            /*var proto = document.location.protocol, host = "whatshelp.io", url = proto + "//static." + host;
            var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
            s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
            var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);*/
          })();
        }


        // $("#theme").attr('href',data[i].data.theme);
        document.querySelector("#theme").setAttribute('href', data[i].data.theme);
      }
      if (data[i].name == "payment-methods") {
        //console.log(data[i].items);
        // console.log("payment methods...");
        for (var j = 0; j < data[i].items.length; j++) {
          if (data[i].items[j].name == "culqui") {
            //console.log("hola");
            if (data[i].items[j].status) {
              // console.log("...culqui...");
              // $("#culqui").attr('src',data[i].items[j].data.src);
              document.querySelector("#culqui").setAttribute('src', data[i].items[j].data.src);
            }
          }
        }
      }
    }
  }

  setSlides(slides){
    // console.log("....slides!!!");
    this.configSlides.next(slides);
  }
}
