import { BrowserModule } from '@angular/platform-browser'; 
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* Modulos Material */
import { MatInputModule, MatButtonModule, MatSelectModule, MatIconModule } from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';

 import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

 import { NgxSpinnerModule } from "ngx-spinner";
/* Fin Material */

/* Inicio Componentes */
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ManzanasComponent } from './manzanas/manzanas.component';
import { SideComponent } from './side/side.component';
import { SideNavComponent } from './sidenav/sidenav.component';
import { PrediosComponent  } from './predios/predios.component';
import { RegistrarDatosComponent  } from './registrar_datos/registrar_datos.component';
import { RegistrarPrediosComponent  } from './registrar_predios/registrar_predios.component';
import { LoaderComponent  } from './global/loader/loader.component';

import { ErrorsComponent } from './lib/component/show-errors/errors.component';

import {ErrorDialogComponent} from './lib/component/error-dialog/error-dialog.component';
import {ConfirmationDialogComponent} from './lib/component/confirmation-dialog/confirmation-dialog.component';


/* Fin Componentes */

/* Inicio Services */
import { LoginService } from './lib/service/login.service';
import { CookieManagerService } from './lib/service/cookie.service';
import { FpManzanasService  } from './lib/service/fpmanzanas.service';
import { FpPrediosService  } from './lib/service/fppredios.service';
import { FpTipoViasService  } from './lib/service/fptipovias.service';
import { FpFotoPredioService  } from './lib/service/fpfotopredio.service';
import { LoaderService  } from './lib/service/loader.service';


import { TransactionService  } from './lib/service/transaction.service';
import { SideNavService  } from './lib/service/sidenav.service';
import { ConfigService  } from './lib/service/config.service';
import { ServerService  } from './lib/service/server.service';
import { TabService  } from './lib/service/tab.service';
import { FunctionService  } from './lib/service/function.service';

import { CookieService  } from 'ng2-cookies';

/* Inicio Services */

import { AuthGuard } from './_guards/auth.guard';

import { AppRoutingModule } from './routing.module';

@NgModule({
  entryComponents:[
    ConfirmationDialogComponent,
    ErrorDialogComponent
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ErrorsComponent,
    ManzanasComponent,
    PrediosComponent,
    ConfirmationDialogComponent,
    ErrorDialogComponent,
    SideComponent,
    SideNavComponent,
    RegistrarDatosComponent,
    RegistrarPrediosComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    BrowserAnimationsModule,
    MatInputModule, 
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgxSpinnerModule,
  ],
  providers: [
     { provide: LoginService, useClass:LoginService},
     { provide: CookieManagerService, useClass:CookieManagerService},
     { provide: FpManzanasService, useClass:FpManzanasService},
     { provide: FpPrediosService, useClass:FpPrediosService},
     { provide: FpTipoViasService, useClass:FpTipoViasService},
     { provide: FpFotoPredioService, useClass:FpFotoPredioService},
     { provide: LoaderService, useClass:LoaderService},
     
     
     { provide: TransactionService, useClass:TransactionService},    
     { provide: SideNavService, useClass:SideNavService},
     { provide: ConfigService, useClass:ConfigService}, 
     { provide: ServerService, useClass:ServerService},
     { provide: TabService, useClass:TabService}, 
     { provide: FunctionService, useClass:FunctionService},
     
     { provide: AuthGuard, useClass:AuthGuard},
     CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
