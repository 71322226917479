// show-errors.component.ts
import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';


@Component({
 selector: 'app-errors',
 templateUrl: './errors.component.html',
 styleUrls: ['./errors.component.scss']
})

export class ErrorsComponent {

 constructor(){}
 private static errorMessages = {
   'required': () => 'Por favor complete este campo',
   'minlength': (params) => 'El mínimo número de caracteres es ' + params.requiredLength + '.',
   'maxlength': (params) => 'El máximo número de caracteres es ' + params.requiredLength + '.',
   'pattern': (params) => {
     if(params.requiredPattern.indexOf("0")!==-1){
       return 'Por favor introduzca carácteres válidos (letras y números).'

     }else{ //name
       return 'Por favor introduzca carácteres válidos (solo letras).'
     }
    }, //params.requiredPattern,
   'email':(params)=> {
      return 'Por favor ingrese un email válido'
   },
   'telephone': (params) => {
     return params.message
   },
   'dni':(params)=> params.message,
   'documentType':(params)=>{
    // console.log("I'm here" + params);
     return params.message},
   'carnetType':(params)=>{
    // console.log("I'm here" + params);
     return params.message},
   'cardType':(params)=>{
    // console.log("I'm here" + params);
     return params.message},
   'date':(params)=>{
    // console.log("I'm here" + params);
     return params.message},
   'ruc':(params)=>{
    // console.log("I'm here" + params);
     return params.message}
 };

 @Input()
 private control: AbstractControl;

 shouldShowErrors(): boolean {
   return this.control &&
     this.control.errors &&
     (this.control.dirty || this.control.touched);
 }

 listOfErrors(): string[] {
   return Object.keys(this.control.errors)
     .map(field => this.getMessage(field, this.control.errors[field]));
 }

 private getMessage(type: string, params: any) {
   if(this.control.errors['required'] && type==="email"){
     return
   }
   if(this.control.errors['required'] && type==="documentType"){
     return
   }
   let message = ErrorsComponent.errorMessages[type](params);
   return ErrorsComponent.errorMessages[type](params);
 }

}
