import {map,finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { CookieManagerService } from './cookie.service';

import { environment } from '../../../environments/environment';

import {LoaderService} from './loader.service';

@Injectable()
export class FpFotoPredioService {
	private headers = new Headers();
	constructor(private http: Http,
				private cookie:CookieManagerService,
				private loaderService:LoaderService){
    }
    
    private showLoader(id): void {
      this.loaderService.show(id);
  	}

  	private onEnd(id): void {
      this.loaderService.hide(id);
  	}

    registrarFoto(data){
     	console.log(data);
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});

		this.showLoader({"id":401, "service":"FpTipoViasService", "function":"getTipoVias"});

		return this.http.post(environment.serverURL+"api/auth/savepre" ,data,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(401)),);
    }

    
}