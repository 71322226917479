import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})

export class ErrorDialogComponent{
  public flag: boolean;
  public message: string;
  public title:string;
  public code: string;
  public deadline: string;
  public buttonFlag: boolean;
  public maxUsage: number;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router) {
    this.flag = true; 
    this.message = this.data.message;
    this.title = this.data.title;
    this.buttonFlag = this.data.flag;
    this.code = this.data.code;
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goCatalog(){
    this.dialogRef.close();
    this.router.navigate(['/catalog/categories'])
  }
}
