import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { LoginService } from '../lib/service/login.service';
import { SideNavService } from '../lib/service/sidenav.service';
import { TabService } from '../lib/service/tab.service';
import { FunctionService } from '../lib/service/function.service';

import { Router } from '@angular/router';
import { ConfigService } from '../lib/service/config.service'; 

import {ServerService} from '../lib/service/server.service';
import { Subscription } from 'rxjs';
import { CookieManagerService } from '../lib/service/cookie.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SideNavComponent implements OnInit {
  username: string;
  // Menus
  //menus ;
  subscription: Subscription;


   menus = [
                    {
                      "label": "Manzanas",
                      "link": "/manzanas",
                      "key": "home",
                      "icon": "home",
                      "flag": true
                    }/*,
                    {
                      "label": "Pagos",
                      "key": "catalogo",
                      "link": "/consult",
                      "icon": "monetization_on",
                      "flag": true
                    },
                    {
                      "label": "Tarifas",
                      "key": "carrito",
                      "link": "/consult",
                      "icon": "comment",
                      "flag": false
                    },
                    {
                      "label": "Ayuda",
                      "key": "monedero",
                      "link": "/consult",
                      "icon": "help",
                      "flag": false
                    }*/
                  ]

  menu_alerts=[
    {
      label: 'Cerrar Sesión',
      link: '/login',
      icon: 'power_settings_new'
    }
  ];

 

  private currentPlatform: number;

  constructor(private router: Router,
    private loginService: LoginService,
    private tabService: TabService,
    private configService: ConfigService,
    private serverService: ServerService,
    private sideNavService: SideNavService,
    public functionService:FunctionService,
    private cookie:CookieManagerService) {

    this.currentPlatform = this.serverService.getPlatform();
    if(this.currentPlatform)
        this.username = localStorage.getItem('currentUser');
  }

  ngOnInit() {
    this.subscription = this.configService.updateConfig$.subscribe(
      data=> {
        // console.log(".....sidenav loaded!!");
        //this.menus = this.baseApiService.menus;
        // console.log(this.menus);
      }
    )
  }

  onClick(label, link) {
    this.username = localStorage.getItem('currentUser');
    this.sideNavService.setActive();
    if (label == 'moneda' && label == 'ajustes') {
      localStorage.setItem('link', this.router.url);
    }
    let arrLinks = [];
    // console.log(label);
    if (this.username === null) {
      var nameLabel = label.toLowerCase();
      if (nameLabel == 'perfil' || nameLabel == 'deseados' || nameLabel == "register"
        || nameLabel == 'pedidos' || nameLabel == "alertas"
        || nameLabel == 'atencion' || nameLabel == "sugerencias"
        || nameLabel == "monedero" || nameLabel == "login" ) {
        if(nameLabel !== "register"){
          localStorage.setItem('link', link);
        }
        link = "/login";
        if (nameLabel == "register") {
          localStorage.setItem('activeTab', 'register');
          this.tabService.setRegister();
        } else {
          this.tabService.setLogin();
        }
      }
    }
    arrLinks.push(link);
    this.router.navigate(arrLinks);
  }

  logout(link) {
    this.cookie.removeCookie('api_token');
    this.sideNavService.setActive();
    this.loginService.logout();
    this.router.navigate(['login']);
  }

  goPlaystore() {
    let url: string = "https://play.google.com/store/apps/details?id=com.vexsoluciones.ecommerceapp&hl=es";
    window.location.href = url;
  }
}
