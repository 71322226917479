import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { Observable,Subject} from 'rxjs/Rx';
import { ServerService } from './server.service';

/*
Service that contains global functions that could be used through all the App
*/
@Injectable()
export class FunctionService {
  public hideFlag: boolean;


  public imgProfile$: Observable<any>;
  private imgProfileSubject = new Subject<any>();
  public imgProfile: any;

  public userName: string;
  public updateUserName$: Observable<any>;
  private userNameSubject = new Subject<any>();

  private currentPlatform: number;

  public emailUser: any;
  public emailUser$: Observable<any>;
  private emailUserSubject = new Subject<any>();

  public side: boolean;
  public side$: Observable<any>;
  private sideSubject = new Subject<any>(); 

  constructor(private serverService: ServerService){
    this.currentPlatform=this.serverService.getPlatform();
    this.hideFlag = false;
    
    this.imgProfile ="assets/images/placeholder.png";
    this.setImgProfile(this.imgProfile);
    this.imgProfile$= this.imgProfileSubject.asObservable();

    if(this.currentPlatform && localStorage.getItem("currentUser") != null ){
      this.userName = localStorage.getItem("currentUser");

    }else{
      this.userName = "";
    }

    this.updateUserName$ = this.userNameSubject.asObservable();
    this.setUserName(this.userName);

    if(this.currentPlatform && localStorage.getItem("email_user") != null ){
      this.emailUser = localStorage.getItem("email_user");
    }else{
      this.emailUser = null;
    }
    this.emailUser$ = this.emailUserSubject.asObservable();
    this.setEmailUser(this.emailUser);


    this.side=true;
    this.side$ = this.sideSubject.asObservable();
    this.setSide(this.side);
  }

 
  public hideSide(){
    this.hideFlag = true;
  }

  public showSide(){
    this.hideFlag = false;
  }

  public setImgProfile(imgProfile){
    this.imgProfileSubject.next(imgProfile);
    this.imgProfile=imgProfile;
  }

  public getImgProfile(){
    return this.imgProfile;
  }
  public setUserName(userName){
    this.userNameSubject.next(userName);
    this.userName= userName;
  }

  public getUserName(){
    return this.userName;
  }

  public setEmailUser(emailUser){
    this.emailUserSubject.next(emailUser);
    this.emailUser = emailUser;
  }

  public setSide(side){
    this.sideSubject.next(side);
    this.side = side;
  }

}
