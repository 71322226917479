import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationExtras } from "@angular/router";
import { FpManzanasService  } from '../lib/service/fpmanzanas.service';
import { ConfirmationDialogComponent } from '../lib/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { CookieManagerService } from "../lib/service/cookie.service";
@Component({
  selector: 'app-manzanas', 
  templateUrl: './manzanas.component.html',
  styleUrls: ['./manzanas.component.scss']
})
export class ManzanasComponent implements OnInit {
	

  constructor(private router: Router,
            private fpManzanasService:FpManzanasService,
            public dialog: MatDialog,
            private cookie:CookieManagerService) {
    
  }

  public showAlert;
  public showResult;
  public nro_manzana;
  public temporal;
  public manzanas = [
    /*{
      "numero_manzana":1148,
      "descripcion_sector":"La Florida",
      "descripcion_lugar":"Barrio Santa Elena"
    },
    {
      "numero_manzana":569,
      "descripcion_sector":"San Pedro",
      "descripcion_lugar":"Barrio San Pedro"
    },
    {
      "numero_manzana":568,
      "descripcion_sector":"San Pedro",
      "descripcion_lugar":"Barrio San Pedro"
    },
    {
      "numero_manzana":575,
      "descripcion_sector":"San Pedro",
      "descripcion_lugar":"Barrio San Pedro"
    }*/


  ]
  ngOnInit() {
    this.consultar();
  }
  
  buscar(){
    
    var temporalFilter = [];

    if(this.nro_manzana != null && this.nro_manzana != ''){

      for(var i = 0; i<this.manzanas.length; i++){
        if(this.temporal[i].numero_manzana == this.nro_manzana ){
          temporalFilter.push(this.temporal[i])
        }
      }

      this.manzanas = temporalFilter;

    }else{
      this.manzanas = this.temporal;
      
    }
  }

  consultar(){
    let data = {
      "usuario_id": this.cookie['id_user'],
      "notificador_id": this.cookie['id_user']
    }

    this.fpManzanasService.getManzanas(data).subscribe((data) => {
      this.manzanas=data.data;
      this.temporal = data.data;
      this.showAlert = true;
    },
    (err) => {
      this.manzanas = [];
      this.showResult = false;  
      let er = JSON.parse(err._body);
    });	
  }

  dialogConfirmar(id){
    this.router.navigate(['/predios/'+id]); 
  }


}
