import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, NavigationExtras } from "@angular/router";
import { FpPrediosService  } from '../lib/service/fppredios.service';
import { ConfirmationDialogComponent } from '../lib/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { CookieManagerService } from "../lib/service/cookie.service";
@Component({
  selector: 'app-predios',
  templateUrl: './predios.component.html',
  styleUrls: ['./predios.component.scss']
})
export class PrediosComponent implements OnInit {
	
   public numeroManzana;
   public predios = [
    /*{
      "predio_id":52396,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N Lugar: BELLAVISTA, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"BRIONES VASQUEZ ESTAUROFILA"
    },
    {
      "predio_id":54213,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N Lugar: BELLAVISTA, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"ROCA GOMEZ, GERONIMO VICTOR"
    },
    {
      "predio_id":3863,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-313 Mz 1148, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"CABRERA MEDINA JOSE"
    },
    {
      "predio_id":197,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-317, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"ASENCIO BOÑON, RAFAEL Y SRA."
    },
    {
      "predio_id":58902,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-329, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"CRISOLOGO CHAVEZ LUZ ANGELICA"
    },
    {
      "predio_id":4020,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-335, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"CHAVEZ MIRANDA ROSA IRENE"
    },
    {
      "predio_id":75245,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-357, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"ARCE ZELADA FIDENCIO ALBERTO"
    },
    {
      "predio_id":75246,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-357, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"ASENCIO SANGAY MARLENY"
    },
    {
      "predio_id":75244,
      "direccion_completa":"Jiron Antenor Orrego Nro S/N-357, Cdra: 2, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"AQUINO SANGAY ROBERTO"
    },
    {
      "predio_id":4990,
      "direccion_completa":"Jiron Jose Olaya Nro 406, Cdra: 4, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"SUCESIÓN INTESTADA CABRERA VASQUEZ ANDRES ALFONSO Y QUIROZ GUTIERREZ ALBINA"
    },
    {
      "predio_id":38874,
      "direccion_completa":"Jiron Jose Olaya Nro 412, Cdra: 4, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"HUATAY SANCHEZ BERTHA "
    },
    {
      "predio_id":44909,
      "direccion_completa":"Jiron Jose Olaya Nro 418, Cdra: 4, Cajamarca",
      "referencia":"",
      "apellidos_nombres":"MINCHAN OCHOA MONICA MARIELA"
    }*/
  ]

  sideFlag;
  second;
  topPosition;

  constructor(private router: Router,
            private fpPrediosService:FpPrediosService,
            public dialog: MatDialog,
            private route: ActivatedRoute,
            private cookie:CookieManagerService) {
    
    this.route.params.subscribe(params => {  
        this.getPredios(params['id']);
    });
    
  }

  changeScreen(){

  }

  autocomplete(event){

  }
  ngOnInit() {
    this.consultar();
  }

  getPredios(id){
    this.numeroManzana = id
    localStorage.setItem('numero_manzana', this.numeroManzana);

    this.fpPrediosService.getPredios(id).subscribe((data) => {
      this.predios=data.data;
    },
    (err) => {
      this.predios = [];
    });  
  }
  

  consultar(){
    /*let data = {
      "notificador_id": this.cookie['id_user']
    }

    this.consultService.getNotificaciones(data).subscribe((data) => {
      this.notificaciones=data.data;
      this.showAlert = true;
    },
    (err) => {
      this.notificaciones = [];
      this.showResult = false;  
      let er = JSON.parse(err._body);
    });	*/
  }

  dialogConfirmar(id){
    
    this.router.navigate(['/registrar_datos/'+id]); 
  }


}
