import {map,finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { CookieManagerService } from './cookie.service';

import { environment } from '../../../environments/environment';

import {LoaderService} from './loader.service';

@Injectable()
export class FpPrediosService {
	private headers = new Headers();
	constructor(private http: Http,
				private cookie:CookieManagerService,
				private loaderService:LoaderService){
    }

    private showLoader(id): void {
      this.loaderService.show(id);
  	}

  	private onEnd(id): void {
      this.loaderService.hide(id);
  	}

    getPredios(numeroManzana){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));
		
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		this.showLoader({"id":201, "service":"FpPrediosService", "function":"getPredios"});

		return this.http.get(environment.serverURL+"api/auth/predios/"+numeroManzana ,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(201)),);
    }

    savePredio(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});

		this.showLoader({"id":202, "service":"FpPrediosService", "function":"savePredio"});

		return this.http.post(environment.serverURL+"api/auth/save/predio" ,data,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(202)),);
    } 

    getPredio(id){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));
		
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		this.showLoader({"id":203, "service":"FpPrediosService", "function":"getPredio"});

		return this.http.get(environment.serverURL+"api/auth/get/predio/"+id ,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(203)),);
    }


    registraDatos(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});

		this.showLoader({"id":204, "service":"FpPrediosService", "function":"registraDatos"});

		return this.http.post(environment.serverURL+"api/auth/regisdatos" ,data,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(204)),);
    } 

}