import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../lib/service/loader.service';
import { LoaderState } from '../../lib/service/data/loader';
import { NgxSpinnerService } from 'ngx-spinner';
//import { BaseApiService } from '../../lib/service/base_api.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit {
  show = false;
  private subscription: Subscription;
  constructor(
    private loaderService: LoaderService,
    private spinner: NgxSpinnerService//,
    //public baseApiService:BaseApiService
  ) {
  }

  ngOnInit() {

    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        let html = document.querySelector('html');
        if(this.loaderService.loaderData.length > 0){
            this.spinner.show();
            // html.style.overflowY = 'hidden';
          }else{
            this.spinner.hide();
            // html.style.overflowY = 'visible';
          }
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
