import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'] 
})
export class ConfirmationDialogComponent{
  public flag: boolean;
  public message: any;
  public buttonFlag: boolean;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.flag = true;
    this.message = this.data.message;
    this.buttonFlag = this.data.flag;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
