import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { Observable,Subject} from 'rxjs/Rx'; 


/*
Service that has all the functions related to sidenav
*/
@Injectable()
export class SideNavService {

  public toggleSide$:Observable<any>;
  private toggleSubject = new Subject<any>();

  public toggleSideFilter$:Observable<any>;
  private toggleSideFilterSubject = new Subject<any>();

  public updateFilter$:Observable<any>;
  private updateFilterSubject = new Subject<any>();

  public updateCategory$:Observable<any>;
  private updateCategorySubject = new Subject<any>();

  public toggleActive$:Observable<any>;
  public toggleFilterActive$:Observable<any>;
  private activeFilterState:boolean;

  private activeSubject = new Subject<any>();
  private activeFilterSubject = new Subject<any>();
  private activeState: boolean;

  public updateProducts$:Observable<any>;
  private updateProductsSubject = new Subject<any>();

  private actualCategory:any;

  public toggleOrder$:Observable<any>;
  private toggleOrderSubject = new Subject<any>();

  public toggleBrand$:Observable<any>;
  private toggleBrandSubject = new Subject<any>();

  public toggleStatus$ : Observable<any>;
  private toggleStatusSubject = new Subject<any>();

  public toggleKey$ : Observable<any>;
  private toggleKeySubject = new Subject<any>();

  public toggleImageResults$: Observable<any>;
  private toogleImageSubject = new Subject<any>();

  private results=[];
  private imageToSearch;

  public toggleTopBar$ : Observable<any>;
  private toggleTopBarSubject = new Subject<any>();

  public toggleToken$: Observable<any>;
  public toggleTokenSubject = new Subject<any>();

  constructor(){
    this.toggleSide$=this.toggleSubject.asObservable();
    this.toggleActive$=this.activeSubject.asObservable();
    this.toggleSideFilter$=this.toggleSideFilterSubject.asObservable();
    this.updateFilter$=this.updateFilterSubject.asObservable();
    this.updateCategory$=this.updateCategorySubject.asObservable();
    this.updateProducts$=this.updateProductsSubject.asObservable();
    this.toggleFilterActive$=this.activeFilterSubject.asObservable();
    this.toggleOrder$=this.toggleOrderSubject.asObservable();
    this.toggleBrand$=this.toggleBrandSubject.asObservable();
    this.toggleStatus$= this.toggleStatusSubject.asObservable();
    this.toggleKey$= this.toggleKeySubject.asObservable();
    this.toggleImageResults$ = this.toogleImageSubject.asObservable();
    this.toggleTopBar$= this.toggleTopBarSubject.asObservable();
    this.toggleToken$= this.toggleTokenSubject.asObservable();
  }

  public setToggle(toggleFlag){
    this.activeState=toggleFlag;
    this.toggleSubject.next(toggleFlag);
  }

  //for the case of links clicked
  public setActive(){
    this.activeState=!this.activeState;
    this.activeSubject.next(this.activeState);
  }

  public setToggleFilter(toggleFlag){
    this.activeFilterState=toggleFlag;
    this.toggleSideFilterSubject.next(toggleFlag);
  }

  public setFilters(filters){
    this.updateFilterSubject.next(filters);
  }

  public setCategory(category){
    this.updateCategorySubject.next(category);
  }

  public setProducts(conditions){
    this.updateProductsSubject.next(conditions);
  }

  public setActiveFilter(){
    this.activeFilterState=!this.activeFilterState;
    this.activeFilterSubject.next(this.activeFilterState);
  }

  public getActualCategory(){
    return this.actualCategory;
  }

  public setActualCategory(category){
    this.actualCategory=category;
  }

  public setOrder(order){
    this.toggleOrderSubject.next(order);
  }

  public setBrand(brand){
    this.toggleBrandSubject.next(brand);
  }

  public setStatusFlag(status){
    this.toggleStatusSubject.next(status);
  }

  public setSearchKey(key){
    this.toggleKeySubject.next(key);
  }

  public setImagesResults(results, image){
    this.results = results;
    this.imageToSearch= image;
    this.toogleImageSubject.next(results);
  }

  public getResults(){
    return this.results;
  }

  public getImageToSearch(){
    return this.imageToSearch;
  }

  public toggleTopbar(flag){
    this.toggleTopBarSubject.next(flag);
  }

  public setToken(token){
    this.toggleTokenSubject.next(token);
  }
}
