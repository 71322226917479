import { Component,ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SideComponent } from './side/side.component';
import { SideNavService } from './lib/service/sidenav.service';
import { FunctionService } from './lib/service/function.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(SideComponent) side: SideComponent;
  @ViewChild('snav') sidenav: MatSidenav;
  @ViewChild('snavFilter') sidenavFilter: MatSidenav;

  title = 'notarios-satcaj';
  scrollHandler;

  selectedValue: string = "";
  items = [
    { value: "0", view: "zero" },
    { value: "1", view: "one" },
    { value: "2", view: "Two" }
  ];

  public sideFlag;
  constructor( private sideNavService: SideNavService,
    public functionService: FunctionService){
    this.sideFlag = false;
  }

  ngOnInit() {
    this.sideFlag = false;
    this.checkSides();
    this.sideNavService.toggleTopBar$.subscribe(
      flag=>{
        if(!flag) this.functionService.hideSide();
        else this.functionService.showSide();
      }
    )

  }


  /*Detect swipe event and  activate sidenav while fixing the page using a service*/
  onSwipe(event) {
    if (this.side != null) {
      //if (UtilFunctions.checkSlide(event) && UtilFunctions.checkProducts(event)) {
        let html = document.querySelector('html');
        if (!this.sidenav.opened) {
          html.style.overflowY = 'hidden';
          html.style.position='fixed';
        } else {
          html.style.overflowY = 'visible';
          html.style.position='relative';
        }
        this.sidenav.toggle();
      //}
    }
  }

  checkSides() {
    this.sideNavService.toggleSide$.subscribe(
      data => {
        let html = document.querySelector('html');
        if (!this.sidenav.opened) {
          html.style.overflowY = 'hidden';
          html.style.position='fixed';
        } else {
          html.style.overflowY = 'visible';
          html.style.position='relative';
        }
        this.sidenav.toggle();
      }
    )
    this.sideNavService.toggleActive$.subscribe(
      data => {
        this.sidenav.toggle();
      }
    )
    this.sideNavService.toggleSideFilter$.subscribe(
      data => {
        let html = document.querySelector('html');
        if (!this.sidenavFilter.opened) {
          html.style.overflowY = 'hidden';
          html.style.position='fixed';
        } else {
          html.style.overflowY = 'visible';
          html.style.position='relative';
        }
        this.sidenavFilter.toggle();
      }
    )

    this.sideNavService.toggleFilterActive$.subscribe(
      data => {
        this.sidenavFilter.toggle();
      }
    )
  }

  changeOverflow() {
    let html = document.querySelector('html');
    html.style.position='relative';
    html.style.overflowY = 'visible';
  }
} 
